import React, { useEffect } from 'react';
import { useTranslate } from 'react-redux-multilingual';
import { Element } from 'react-scroll';

//Material
import { Box, Grid, Tab, Typography } from '@mui/material';

import vector1 from '../../../assets/Vector1.png';
import vector2 from '../../../assets/Vector2.png';
import vector3 from '../../../assets/Vector3.png';
import vector4 from '../../../assets/Vector4.png';

//Components
import styled from '@emotion/styled';
import { TabContext, TabPanel } from '@mui/lab';
import { useState } from 'react';
import { StyledLabel } from '../../../components/StyledComponents';
import { StyledSubTabs } from '../../../components/SubTabs.styles';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

export default function WhyUs() {
  const [value, setValue] = useState('0');
  const [overflow, setOverflow] = useState(false);
  const translate = useTranslate();
  const cardsSec = [
    {
      vector: vector1,
      title: translate('visitCard_whyUs_item_title_1'),
      desc: translate('visitCard_whyUs_item_desc_1')
    },
    {
      vector: vector2,
      title: translate('visitCard_whyUs_item_title_2'),
      desc: translate('visitCard_whyUs_item_desc_2')
    },
    {
      vector: vector3,
      title: translate('visitCard_whyUs_item_title_3'),
      desc: translate('visitCard_whyUs_item_desc_3')
    },
    {
      vector: vector4,
      title: translate('visitCard_whyUs_item_title_4'),
      desc: translate('visitCard_whyUs_item_desc_4')
    }
  ];
  const StyledTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
    textTransform: 'none'
  }));
  const handleChangeTab = (event, newValue) => {
    setValue(`${newValue}`);
  };

  const isOverflow = () => {
    const element = document.getElementsByClassName('MuiTabs-flexContainer');

    if (element) {
      if (element[0].scrollWidth > element[0].clientWidth) {
        setOverflow(true);
      } else setOverflow(false);
    }
  };
  useEffect(() => isOverflow(), []);

  return (
    <Grid item className="mainPage-whyUs-wrapper">
      <StyledLabel style={{ textAlign: 'center', margin: '0' }}>
        {translate('visitCard_whyUs')}
      </StyledLabel>
      <Element name="solutions" />

      <Grid
        container
        style={{
          marginBottom: '135px',

          margin: '0 auto',
          // marginLeft: '0px',
          // marginRight: '0px',
          marginTop: overflow ? '50px' : '110px',
          width: '100%'
        }}
      >
        {overflow && (
          <Grid item xs={12} lg={12}>
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: 'row',
                margin: '0 auto 75px'
              }}
            >
              <div className="mainPage-FAQ-overflow-marker-2">
                <ArrowBackIcon fontSize="medium" style={{ color: '#0D7834' }} />
              </div>
              <div className="mainPage-FAQ-overflow-marker">
                <ArrowForwardIcon fontSize="medium" style={{ color: '#0D7834' }} />
              </div>
            </div>
          </Grid>
        )}
        <Box
          sx={{
            width: '100%',
            typography: 'body1',
            position: 'relative',
            marginTop: '30px'
          }}
        >
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <StyledSubTabs
                // value={value}

                onChange={handleChangeTab}
                aria-label="lab API tabs example"
              >
                <StyledTab label={translate('visitCard_whyUs_item_tab_1')} value={'0'} />
                <StyledTab label={translate('visitCard_whyUs_item_tab_2')} value={'1'} />
                <StyledTab label={translate('visitCard_whyUs_item_tab_3')} value={'2'} />
                <StyledTab label={translate('visitCard_whyUs_item_tab_4')} value={'3'} />
              </StyledSubTabs>
            </Box>
            {cardsSec.map((card, index) => {
              return (
                <TabPanel key={index} value={`${index}`} className="mainPage-whyUs-tabPanel">
                  <Grid container spacing={0}>
                    <Grid item xs={12} md={2} lg={2}>
                      <img width="116px" height="116px" src={card.vector} alt="img" />
                    </Grid>
                    <Grid item xs={12} md={10} lg={10}>
                      <Typography
                        style={{
                          fontFamily: 'Noto Sans',
                          fontStyle: 'normal',
                          fontWeight: '400',
                          fontSize: '18px',
                          lineHeight: '25px'
                        }}
                      >
                        {card.title}
                      </Typography>
                    </Grid>
                    <Grid xs={12} item style={{ marginTop: '35px', marginBottom: '16px' }}>
                      <Typography
                        style={{
                          fontFamily: 'Noto Sans',
                          fontStyle: 'normal',
                          fontWeight: '900',
                          fontSize: '32px',
                          lineHeight: '44px',
                          color: '#0D7834',
                          opacity: '0.2'
                        }}
                        variant="h4"
                      >
                        {translate('visitCard_whyUs_solution')}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        style={{
                          fontFamily: 'Noto Sans',
                          fontStyle: 'normal',
                          fontWeight: '400',
                          fontSize: '16px',
                          lineHeight: '22px',
                          whiteSpace: 'break-spaces'
                        }}
                      >
                        {card.desc}
                      </Typography>
                    </Grid>
                  </Grid>
                </TabPanel>
              );
            })}
          </TabContext>
        </Box>
      </Grid>
    </Grid>
  );
}
